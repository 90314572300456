var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "siteInformationArea" }, [
    _c("div", { staticClass: "infoInnerArea" }, [
      _c(
        "div",
        { staticClass: "infoTopArea" },
        [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.loading ? "" : _vm.chatContanct()
                }
              }
            },
            [
              !_vm.loading
                ? _c("img", {
                    staticStyle: { width: "17px", margin: "0 4px 0 0" },
                    attrs: {
                      src: require("../../../assets/images/newPersonalCenter/server.png")
                    }
                  })
                : _c("i", { staticClass: "el-icon-loading" }),
              _vm._v("\n        " + _vm._s(_vm.$t("contactus")) + "\n      ")
            ]
          ),
          _c("router-link", { attrs: { to: { name: "privacy" } } }, [
            _vm._v(_vm._s(_vm.$t("privacy")))
          ])
        ],
        1
      ),
      _c("div", { staticClass: "infoFoonterArea" }, [
        _vm._v(
          "\n      2014-" +
            _vm._s(_vm.year) +
            " MeLinked " +
            _vm._s(_vm.$t("companyright")) +
            "\n      "
        ),
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" } },
          [_vm._v("蜀ICP备15003211号-1")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
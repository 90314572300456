<template>
  <div class="siteInformationArea">
    <div class="infoInnerArea">
      <div class="infoTopArea">
        <a @click="loading ? '' : chatContanct()">
          <img
            src="../../../assets/images/newPersonalCenter/server.png"
            style="width: 17px; margin: 0 4px 0 0"
            v-if="!loading"
          />
          <i v-else class="el-icon-loading"></i>
          {{ $t("contactus") }}
        </a>
        <router-link :to="{ name: 'privacy' }">{{ $t("privacy") }}</router-link>
      </div>
      <div class="infoFoonterArea">
        2014-{{ year }} MeLinked {{ $t("companyright") }}
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >蜀ICP备15003211号-1</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
//创建客服群
import { createCustomerGroup } from "@/api/rest";
export default {
  name: "melinkedTips",
  components: {
    VueQr,
  },
  data() {
    return {
      // 版权号
      year: "",
      QRCodeUrl: "https://m.melinked.com/",
      QRCodeLogo: "./favicon.png",
      // 拉取客服群状态
      loading: false,
    };
  },
  mounted() {
    // 动态刷新最新的版权年限
    this.year = new Date().getFullYear();
  },
  methods: {
    // 点击联系我们，判断是否登录，没有登录则创建游客信息
    async chatContanct() {
      let userCatch = this.$store.getters.userInfo;
      if (userCatch.id) {
        /** 5.3.2创建一个新的客服群,并推送消息 */
        // 1.创建一个客服群
        const TOKEN = "BF03DF03D6925CDsf555828633A096D0C234";
        const result = await createCustomerGroup(TOKEN).catch(() => {});
        if (result.code == 200) {
          const { groupHeader, groupId, groupName } = result.data.data;
          // 2.打开IM
          setTimeout(() => {
            this.$openIM({
              id: groupId,
              displayName: groupName,
              avatar: groupHeader,
              isGroup: true,
            });
          }, 100);
        }
      } else {
        this.loading = true;
        await this.handlerInitToursits();
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.siteInformationArea {
  width: 100%;
  height: 114px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .qrStyle {
    margin-left: 10px;
    flex-shrink: 0;
  }

  .infoInnerArea {
    height: 86px;
    flex: 1;
    min-width: 0;
    font-size: 12px;
    color: #333333;
    padding-left:20px
    .infoTopArea {
      height: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
      justify-content:flex-start;

      a {
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;

        &:last-child {
          margin-left: 20px;
        }
      }
    }

    .infoFoonterArea {
      width: 100%;
      text-align: left;
      box-sizing: border-box;
      line-height: 18px;
      // padding: 0 8px;
    }
  }
}
</style>
